<template>
  <div class="top-bar d-none d-lg-flex" v-if="getActivePlan.status">
    <a href="https://heocademy.com" target="_blank" class="text-white"
      >Heocademy.com Yönetim Paneli 2024 | {{ getActivePlan?.package?.name }}
    </a>
    <a class="text-white" :href="`https://${getSettings.website}`">
      {{ getSettings.title }}
    </a>
  </div>
</template>

<script>
export default {
  computed: {
    getSettings() {
      return this.$store.state.User.settings;
    },
    getActivePlan() {
      return this.$store.state.User.activePlan;
    },
  },
};
</script>

<style></style>
