<template>
  <div
    class="video-is-uploading"
    :style="`
background: linear-gradient(90deg, rgba(38,228,145,1) ${percent}%, rgba(255,255,255,1) ${
      percent + 4
    }%);`"
  >
    <div class="col-2 col-md-1 d-flex align-items-center">
      <UploadLoading></UploadLoading>
      <span class="ml-2"> {{ percent.toFixed(2) || 0 }}%</span>
    </div>
    <div class="col-2 col-md-1">
      <video
        loop
        muted
        autoplay
        :src="getVideoThatIsUploading.form.videoPath"
        height="40"
      ></video>
    </div>
    <div style="font-size: 13px" class="col-8 col-md-10 overflow-hidden">
      <div class="overflow-hidden">
        {{ getVideoThatIsUploading.form.description }} isimli videonuz
        yükleniyor...
      </div>
    </div>
  </div>
</template>

<script>
import UploadLoading from "../Shared/UploadLoading.vue";
import { convertM2H } from "gurhan/helpers/Date";
export default {
  data() {
    return {
      seededDuration: 0,
      maxDuration: 40,
    };
  },
  computed: {
    getVideoThatIsUploading() {
      return this.$store.state.Products.videoThatIsUploadıng;
    },
    progressEvent() {
      return this.$store.state.progressEvent;
    },
    percent() {
      return (
        ((this.getVideoThatIsUploading?.upload.startByte +
          this.progressEvent.loaded) *
          100) /
          this.getVideoThatIsUploading?.upload.totalByte || 0
      );
    },
  },
  methods: {
    convertM2H,
  },
  components: { UploadLoading },
};
</script>

<style></style>
