<template>
  <div class="bottom-bar d-md-none d-flex align-items-center">
    <component
      :is="item.external ? 'a' : 'router-link'"
      :to="item.to"
      :href="item.to"
      v-for="(item, i) in items"
      :target="item.external ? '_blank' : ''"
      :key="i"
      class="text-center"
    >
      <h4>
        <i v-if="item.icon" :class="`icon-${item.path}`"></i>
        <img v-else src="/images/menu.png" width="20" alt="" />
      </h4>
      <div>
        <small> {{ $t(item.name) }}</small>
      </div>
    </component>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          external: false,
          name: "Ana Sayfa",
          icon: true,
          path: "home",
          to: "/",
        },
        {
          external: false,
          name: "users",
          icon: true,
          path: "user",
          to: "/user-list",
        },
        {
          external: false,
          name: "uploadProduct",
          icon: true,
          path: "plus",
          to: "/new-product",
        },
        {
          name: "Akademim",
          icon: true,
          path: "location",
          external: true,
          to: "https://test.com",
        },
        {
          external: false,
          name: "myProfile",
          icon: true,
          path: "user",
          to: "/profile",
        },
      ],
    };
  },
};
</script>

<style></style>
