<template>
  <Modal persistent sm v-model="getLoadingModal" hide-footer hide-header>
    <div v-if="!getAlertBox.status">
      <PageLoading style="height: auto !important"></PageLoading>
      <div class="mt-4 text-center">{{ $t("processIsContiuning") }}</div>
      <div v-if="getProgressEvent.uploading" class="text-center mt-4">
        {{ getProgressEvent.progress }}%
      </div>
    </div>
    <div class="text-center" v-else>
      <img
        width="60"
        :src="`/images/${getAlertBox.variant}.svg`"
        alt="icon-status"
      />
      <div class="mt-4">
        {{ getAlertBox.message }}
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "./Modal";
import PageLoading from "./PageLoading.vue";
export default {
  components: { Modal, PageLoading },
  computed: {
    getLoadingModal() {
      return this.$store.state.loadingModal;
    },
    getAlertBox() {
      return this.$store.state.loadingModalAlertBox;
    },
    getProgressEvent() {
      return this.$store.state.progressEvent;
    },
  },
  watch: {
    getAlertBox: {
      deep: true,
      handler(data) {
        if (data.status)
          setTimeout(() => {
            this.$store.commit("SET_LOADING_MODAL", {
              loading: false,
              alertBox: { status: false },
            });
          }, 2500);
      },
    },
  },
};
</script>

<style></style>
