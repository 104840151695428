<template>
  <transition
    mode="out-in"
    enter-active-class="slide-in"
    leave-active-class="slide-out"
  >
    <div
      id="mail-drawer"
      @click="HANDLE_CLICK"
      v-if="getMailDrawer.status"
      class="mail-drawer"
    >
      <ModuleLocked v-if="!sendEmailModule" />
      <div v-else id="mail-content" class="mail-drawer-content">
        <div class="mail-drawer-header">
          <h6>{{ $t("sendMail") }}</h6>
          <i
            @click="
              $store.commit('SET_MAIL_DRAWER', { status: false, form: {} })
            "
            class="icon-cancel"
          ></i>
        </div>
        <div class="mail-drawer-body">
          <section class="vid-title-sec">
            <div class="container">
              <form @submit.prevent="SEND_TO_MAIL">
                <div v-if="getActiveUser.roleId !== 3">
                  <div class="vid-title my-2" v-if="emailForm.bcc === ''">
                    <h2 class="title-hd">{{ $t("sendMailAddress") }}</h2>
                    <div class="form_field">
                      <textarea
                        type="text"
                        maxlength="100"
                        required
                        disabled
                        v-model="emailForm.to"
                        :placeholder="$t('addHere')"
                      ></textarea>
                    </div>
                  </div>
                  <div class="abt-vidz-pr" v-else>
                    <h2 class="title-hd">{{ $t("sendMailAddresses") }}</h2>
                    <div class="form_field">
                      <textarea
                        required
                        disabled
                        v-model="emailForm.bcc"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="vid-title my-2">
                  <h2 class="title-hd">{{ $t("enterMailTitle") }}</h2>
                  <div class="form_field">
                    <textarea
                      type="text"
                      maxlength="100"
                      required
                      v-model="emailForm.subject"
                      :placeholder="$t('addHere')"
                    ></textarea>
                  </div>
                </div>
                <!--vid-title-->
                <div class="abt-vidz-pr">
                  <h2 class="title-hd">{{ $t("enterMailDescription") }}</h2>
                  <div class="form_field">
                    <textarea
                      :placeholder="$t('addHere5000')"
                      required
                      v-model="emailForm.content"
                      maxlength="5000"
                    ></textarea>
                  </div>
                </div>
                <div class="mt-4" v-if="emailForm.files.length !== 0">
                  <h5 class="mb-4">
                    {{ $t("attachments") }} ({{ emailForm.files.length }})
                  </h5>
                  <div class="bg-light card">
                    {{ $t("limit10Files") }}
                  </div>
                  <span
                    class="btn btn-pill m-2"
                    v-for="(file, i) in emailForm.files"
                    :key="i"
                    >{{ file.name }}
                    <i
                      @click="emailForm.files.splice(i, 1)"
                      class="icon-cancel"
                    ></i>
                  </span>
                </div>
                <div
                  class="mt-4 text-center"
                  v-if="emailForm.files.length < 10"
                >
                  <button
                    @click.prevent="$refs.file.click()"
                    class="btn btn-pill btn-dark"
                  >
                    + {{ $t("addFile") }}
                  </button>
                  <input
                    class="d-none"
                    type="file"
                    @change="SET_FILE"
                    multiple
                    name="file"
                    id="file"
                    ref="file"
                  />
                </div>
                <div class="clearfix"></div>
                <div class="btn-sbmit">
                  <button type="submit" :disabled="mailIsSending">
                    <loading v-if="mailIsSending"></loading>
                    <span v-else>{{ $t("sendMail") }}</span>
                  </button>
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Mail from "../../mixins/Mail";
export default {
  mixins: [Mail],
  data() {
    return {
      reSizeCount: 0,
      locked: true,
    };
  },
  methods: {
    HANDLE_CLICK(e) {
      if (e.target.id === "mail-drawer")
        this.$store.commit("SET_MAIL_DRAWER", { status: false, form: {} });
      // this.locked = false;
    },
  },
};
</script>

<style></style>
