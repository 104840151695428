import CryptoJS from "gurhan/helpers/Crypto";
const crypto = new CryptoJS("MAKETODAYAMAZING");
export default {
  methods: {
    listenMessage() {
      const vm = this;
      window.addEventListener(
        "message",
        function (e) {
          // e.data hold the message from child
          if (e.data.name === "rezzervasyon") {
            vm.$store.dispatch("GET_REZZERVATION_INFO_AND_ROOM", e.data);
            vm.$store.commit("SET_LOADING_MODAL", {
              loading: true,
              alertBox: {
                status: true,
                variant: "success",
                message: vm.$t("rezzConnectionIsSucceed"),
              },
            });
          }
        },
        false
      );
    },
    SET_TO_CONTROL_REZ_INFO() {
      const vm = this;
      const rezInfo = localStorage.getItem("rez_info");
      try {
        if (rezInfo) {
          vm.$store.dispatch(
            "GET_REZZERVATION_INFO_AND_ROOM",
            JSON.parse(crypto.decrypto(rezInfo))
          );
        }
      } catch (error) {
        console.log(error);
        vm.$store.commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "danger",
            message: this.$t("rezzConnectionIsFailed"),
          },
        });
      }
    },
  },
  created() {
    this.listenMessage();
    this.SET_TO_CONTROL_REZ_INFO();
  },
};
